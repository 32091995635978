/**
 * Objeto do tipo booleano
 * @param label
 * @param defaultValue
 * @param columns
 * @returns {{type: string, default: boolean, ui: {label: *, widgetConfig: {enumSource: *[]}}}}
 */
export function schemaBoolean(label, defaultValue = true, columns = 12) {
  return {
    type: 'boolean',
    default: defaultValue,
    ui: {
      label,
      columns,
      "widgetConfig": {
        "enumSource": [
          {
            "label": "Sim",
            "value": true
          },
          {
            "label": "Não",
            "value": false
          }
        ]
      },
    }
  }
}

/**
 * Objteto do tipo texto
 * @param label
 * @param required
 * @param columns
 * @returns {{type: string, ui: {label: *, columns: number}, rules}}
 */
export function schemaTextField(label, required = false, columns = 12) {
  return {
    type: 'string',
    ui: {
      label,
      columns,
    },
    rules: getRules({ required })
  }
}

/**
 * Objteto do tipo texto
 * @param label
 * @param required
 * @param columns
 * @returns {{type: string, ui: {label: *, columns: number}, rules}}
 */
export function schemaColorPicker(label, required = false, columns = 12) {
  return {
    type: 'string',
    ui: {
      widget: 'form-input-color-picker',
      label,
      columns,
      widgetConfig: {
        name: "joão"
      }
    },
    rules: getRules({ required })
  }
}

/**
 * Objeto do tipo númro
 * @param label
 * @param required
 * @param columns
 * @returns {{type: string, ui: {label: *, columns: number}, rules}}
 */
export function schemaNumber(label, required = false, columns = 12) {
  return {
    type: 'number',
    ui: {
      label,
      columns,
    },
    rules: getRules({ required })
  }
}

/**
 * Obtém objeto de regras
 * @param rules
 */
function getRules(rules) {
  let _rules = {};

  if (rules.required) {
    _rules['required'] = {
      "value": true,
      "errMsg": "Campo de preenchimento obrigatório"
    }
  }

  return _rules;
}