<template>
    <modal :name="modalName" height="auto" width="900" :lazy="true" :scrollable="true" :clickToClose="false">
        <CloseModal :close="close" />
        <div class="modalbox">
            <div class="modalbox-content">
                <div class="form-group">
                    <label>
                        <i class="text-danger">*</i>Título
                    </label>
                    <input class="form-control" type="text" v-model="item.titulo">
                </div>
                <div class="form-group">
                    <label>
                        <i class="text-danger">*</i>Descrição
                    </label>
                    <input class="form-control" type="text" v-model="item.descricao">
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>
                                Banco de execução
                            </label>
                            <select class="form-control" v-model="item.banco">
                                <option value="local">Banco de dados Versotech</option>
                                <option value="erp">Banco de dados ERP</option>
                            </select>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>
                                <i class="text-danger">*</i>Ordem
                            </label>
                            <input class="form-control" type="text" v-model="item.ordem">
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>
                                <i class="text-danger">*</i>Cor de exibição
                            </label>
                            <InputColorPicker v-model="item.cor" />
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        <i class="text-danger">*</i>Filtro SQL
                    </label>
                    <div class="codemirror">
                        <codemirror v-model="item.filtro_sql" :options="code_options"></codemirror>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" v-on:click="onSubmitForm" class="btn btn-primary">
                Salvar Alterações
            </button>
            <button type="button" class="btn btn-secondary" v-on:click="close">
                Fechar
            </button>
        </div>
    </modal>
</template>

<script>
  import CloseModal from "../../../components/CloseModal";

  import { codemirror } from 'vue-codemirror'

  // theme css
  import 'codemirror/lib/codemirror.css'
  import 'codemirror/theme/monokai.css'

  // language js
  import'codemirror/addon/selection/active-line.js'
  import 'codemirror/mode/sql/sql.js'
  import 'codemirror/addon/display/autorefresh.js'

  // autoCloseTags
  import'codemirror/addon/edit/closetag.js'
  import InputColorPicker from "../../../components/InputColorPicker";


  export default {
    name: "EditEtapa",
    components: {
      InputColorPicker,
      CloseModal,
      codemirror,
    },
    props: [
      'item',
      'modalName',
      'onSubmit',
      'close'
    ],
    methods: {
      onSubmitForm() {
        this.onSubmit(this.item);
      }
    },
    data() {
      return {
        code_options: {
          tabSize: 4,
          styleActiveLine: true,
          lineNumbers: true,
          line: true,
          mode: 'text/x-sql',
          autoRefresh:true,
          lineWrapping: true,
          theme: 'monokai',
          showCursorWhenSelecting: true,
          highlightSelectionMatches: {
            showToken: /\w/,
            annotateScrollbar: true
          },
        }
      }
    }
  }
</script>