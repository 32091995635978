<template>
    <div>
        <EditEtapa
            :item="etapaItem"
            modalName="modal-edit-etapa"
            :onSubmit="onSubmitEtapa"
            :close="closeEditEtapa"
        />

        <EditFunil
            :item="funilItem"
            modalName="modal-edit-funil"
            :onSubmit="onSubmitFunil"
        />

        <CardFunil
            v-for="funil in funis"
            :key="funil.id"
            :funil="funil"
            :openEdit="openEditFunil"
            :deleteFunil="deleteFunil"
            :updateFunil="updateFunil"
            :openCreateEtapa="openCreateEtapa"
            :openEditEtapa="openEditEtapa"
            :deleteEtapa="deleteEtapa"
        />

        <CButton variant="outline" color="dark" class="col" @click="openCreateFunil">
            Incluir novo funil
        </CButton>
    </div>
</template>

<script>
  import clone from "just-clone";
  import { get } from '../../helpers/apiRequest';
  import EditEtapa from "./components/EditEtapa";
  import EditFunil from "./components/EditFunil";
  import CardFunil from "./components/CardFunil";
  import {confirmDelete, confirmStore, confirmUpdate} from "../../helpers/crud";
  import ModalEdit from "../../components/crud/ModalEdit";

  const funilInitialState = () => ({
    "titulo"          : '',
    "ativo"           : true,
    "descricao"       : '',
    "ordem"           : 1,
    "filtra_carteira" : true,
  });

  const etapaInitialState = (funilId) => ({
    "titulo"    : '',
    "descricao" : '',
    "ordem"     : 1,
    "cor"       : '#FFF',
    "acompanhamento_id": funilId,
    "banco"     : 'local',
    "filtro_sql": 'dataultimacompra >= current_date - interval \'180 days\'',
  });

  export default {
    name: "FunilAcompanhamento",
    components: {
      ModalEdit,
      CardFunil,
      EditEtapa,
      EditFunil,
    },
    data() {
      return {
        'etapaItem': etapaInitialState(),
        'funilItem': funilInitialState(),
        'funis': []
      };
    },
    methods: {
      /// Funis
      openCreateFunil() {
        this.funilItem = funilInitialState();
        this.$modal.show('modal-edit-funil');
      },
      openEditFunil(funil) {
        this.funilItem = clone(funil);
        this.$modal.show('modal-edit-funil');
      },
      closeEditFunil() {
        this.$modal.hide('modal-edit-funil');
      },
      onSubmitFunil(funil) {
        if (funil.id) {
          this.updateFunil(funil)
        } else {
          this.storeFunil(funil);
        }
      },
      updateFunil(funil) {
        confirmUpdate(`/admin/acompanhamento-clientes/acompanhamentos/${funil.id}`, funil, this.loadFunisAndCloseModais);
      },
      storeFunil(funil) {
        confirmStore(`/admin/acompanhamento-clientes/acompanhamentos/`, funil, this.loadFunisAndCloseModais);
      },
      deleteFunil(funil) {
        confirmDelete(`/admin/acompanhamento-clientes/acompanhamentos/${funil.id}`, this.loadFunisAndCloseModais);
      },

      /// Etapas
      openCreateEtapa(funilId) {
        this.etapaItem = etapaInitialState(funilId);
        this.$modal.show('modal-edit-etapa');
      },
      openEditEtapa(etapa) {
        this.etapaItem = clone(etapa);
        this.$modal.show('modal-edit-etapa');
      },
      closeEditEtapa() {
        this.$modal.hide('modal-edit-etapa');
      },
      onSubmitEtapa(etapa) {
        if (etapa.id) {
          this.updateEtapa(etapa)
        } else {
          this.storeEtapa(etapa);
        }
      },
      updateEtapa(etapa) {
        confirmUpdate(`/admin/acompanhamento-clientes/etapas/${etapa.id}`, etapa, this.loadFunisAndCloseModais);
      },
      storeEtapa(etapa) {
        confirmStore(`/admin/acompanhamento-clientes/etapas`, etapa, this.loadFunisAndCloseModais);
      },
      deleteEtapa(etapa) {
        confirmDelete(`/admin/acompanhamento-clientes/etapas/${etapa.id}`, this.loadFunisAndCloseModais);
      },

      /// Carregamento de funis
      loadFunis(onSuccess) {
        get(`/admin/acompanhamento-clientes`)
          .then((response) => {
            this.funis = response
            if (onSuccess) {
              onSuccess()
            }
        });
      },
      loadFunisAndCloseModais() {
        this.loadFunis(() => {
          this.closeEditEtapa();
          this.closeEditFunil();
        })
      }
    },
    beforeMount() {
      this.loadFunis();
    }
  }
</script>
